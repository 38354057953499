/* eslint-disable jsx-a11y/anchor-has-content */
import { Avatar } from "@resource/atlas/avatar/Avatar";
import type { AtlasAvatarProps } from "@resource/atlas/avatar/types";
import { Menu } from "@resource/atlas/menu";
import type { AtlasMenuContentItem } from "@resource/atlas/menu/types";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import { useExtensionController } from "client/app/extension/__components/ExtensionControllerProvider";
import NextLink from "next/link";
import { signOut } from "next-auth/react";
import { useCallback, useMemo, useState } from "react";
import useExtension from "react-hooks/useExtension";
import useExtensionLink from "react-hooks/useExtensionLink";
import { Permission } from "shared/auth/permissions";
import {
  CHROME_EXTENSION_INSTALL_PAGE,
  CHROME_EXTENSION_TUTORIAL,
  PRIVACY_POLICY_PAGE,
  TERMS_OF_SERVICE_PAGE,
} from "utils/constants/href";

type AccountMenuProps = {
  triggerSize?: AtlasAvatarProps["size"];
  target?: "self" | "_blank";
  hideDashboard?: boolean;
};

function AccountMenu({
  triggerSize = "medium",
  target,
  hideDashboard,
}: AccountMenuProps) {
  const { installed: isExtensionInstalled } = useExtension();
  const { sendMessageToParent } = useExtensionController();
  const extensionLink = useExtensionLink();
  const [loggingOut, setLoggingOut] = useState(false);
  const { onLogout } = useAuthContext();
  const logEvent = useLogEvent({ component: "AccountMenu" });
  const { checkRolePermissions, user } = useAuthContext();

  const isCandidateUser =
    user?.currentUserMembership?.highestRole?.id === "candidate";

  const header = useMemo(
    () => (
      <div key="account-menu-top" className="px-3 py-1.5 space-y-0.5">
        <p className="text-body-md-heavy text-dark truncate">
          {user?.fullName ?? ""}
        </p>
        {!isCandidateUser && (
          <p className="text-body-md text-subtle truncate">
            {user?.primaryEmail ?? ""}
          </p>
        )}
      </div>
    ),
    [isCandidateUser, user?.fullName, user?.primaryEmail]
  );

  const hasPermission = useCallback(
    ({ key }: AtlasMenuContentItem) => {
      if (key === "organization-settings") {
        return checkRolePermissions(Permission.ORGANIZATION.WRITE);
      }

      return true;
    },
    [checkRolePermissions]
  );

  const logout = useCallback(async () => {
    logEvent("Sidebar Sign Out Clicked");
    setLoggingOut(true);
    sendMessageToParent({
      command: "update-sidepanel-iframe-initialized",
      value: false,
    });
    await onLogout();

    signOut();
  }, [onLogout, sendMessageToParent, logEvent]);

  const items = useMemo(
    () =>
      (
        [
          ...(isCandidateUser
            ? []
            : ([
                ...(hideDashboard
                  ? []
                  : [
                      {
                        _type: "item",
                        children: "Company dashboard",
                        key: "company-dashboard",
                        onSelect: () =>
                          logEvent("Guide Account Menu Dashboard Clicked"),
                        renderWrapper: (props) => (
                          <NextLink href="/" {...props} target={target} />
                        ),
                      } as AtlasMenuContentItem,
                    ]),
                {
                  _type: "item",
                  children: "Account settings",
                  key: "account-and-preferences",
                  onSelect: () =>
                    logEvent(
                      "Guide Account Menu Account & Preferences Clicked"
                    ),
                  renderWrapper: (props) => (
                    <NextLink href="/account" {...props} target={target} />
                  ),
                },
                {
                  _type: "item",
                  children: "Organization settings",
                  key: "organization-settings",
                  onSelect: () =>
                    logEvent(
                      "Guide Account Menu Organization Settings Clicked"
                    ),
                  renderWrapper: (props) => (
                    <NextLink href="/settings" {...props} target={target} />
                  ),
                },
                { _type: "separator", key: "separator-1" },
                {
                  _type: "item",
                  children: "Install Chrome extension",
                  key: "install-chrome-extension",
                  onSelect: () => {
                    logEvent("Menu Install Clicked", {
                      project: "Check and prompt Extension install",
                    });
                    if (isExtensionInstalled) {
                      window.open(CHROME_EXTENSION_INSTALL_PAGE, "_blank");
                    } else {
                      extensionLink.navigate(CHROME_EXTENSION_TUTORIAL);
                    }
                  },
                },
                { _type: "separator", key: "separator-2" },
              ] as AtlasMenuContentItem[])),
          {
            _type: "item",
            children: "Terms of service",
            key: "terms-of-service",
            onSelect: () => logEvent("Account Menu Terms Of Service Clicked"),
            renderWrapper: (props) => (
              <a
                {...props}
                href={TERMS_OF_SERVICE_PAGE}
                target="_blank"
                rel="noreferrer"
              />
            ),
          },
          {
            _type: "item",
            children: "Privacy policy",
            key: "privacy-policy",
            onSelect: () => logEvent("Account Menu Privacy Policy Clicked"),
            renderWrapper: (props) => (
              <a
                {...props}
                href={PRIVACY_POLICY_PAGE}
                target="_blank"
                rel="noreferrer"
              />
            ),
          },
          { _type: "separator", key: "separator-3" },
          {
            _type: "item",
            children: loggingOut ? "Signing out…" : "Sign out",
            key: "sign-out",
            onSelect: logout,
          },
        ] as AtlasMenuContentItem[]
      ).filter(hasPermission),
    [
      isCandidateUser,
      hasPermission,
      logEvent,
      isExtensionInstalled,
      extensionLink,
      target,
      logout,
      loggingOut,
      hideDashboard,
    ]
  );

  return (
    <Menu.Root placement="top-end">
      <Menu.Trigger>
        <Avatar
          as="button"
          size={triggerSize}
          name={user?.fullName}
          image={user?.imageUrl}
          disableTooltip
          aria-label="Open account menu"
        />
      </Menu.Trigger>
      <Menu.Content defaultSize="compact" header={header} items={items} />
    </Menu.Root>
  );
}

export default AccountMenu;
