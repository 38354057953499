/* eslint-disable import/prefer-default-export */
import clsx from "clsx";

import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import { Icon } from "../icon/Icon";
import type { AtlasListItemComponent, AtlasListItemProps } from "./types";

// config
// ------

const COMPONENT_NAME = "ListItem";

const DEFAULT_PROPS = createDefaultProps<AtlasListItemProps>()({
  size: "normal",
} as const);

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// list item
// ---------

/** A list item component. */
export const ListItem = createComponent<AtlasListItemComponent>(
  ({
    size = DEFAULT_PROPS.size,
    isInteractive,
    isSelected,
    isDisabled,
    isDestructive,
    icon,
    isSubtleIcon,
    renderContent,
    trailingContent,
    children,
    ...props
  }) => (
    <div
      {...props}
      className={clsx(
        ROOT,
        `size-${size}`,
        { isInteractive, isSelected, isDisabled, isDestructive, isSubtleIcon },
        props.className
      )}
    >
      <div className={el`content-wrapper`}>
        {icon && <Icon className={el`icon`} content={icon} />}
        <div className={el`content`}>
          {renderContent?.({ children }) ?? (
            <p className={el`default-content`}>{children}</p>
          )}
        </div>
        {trailingContent && <div>{trailingContent}</div>}
      </div>
    </div>
  )
);

// TODO: add renderWrapper support
