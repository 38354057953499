export function getApplicationIdFromUrl({ url }: { url: string }) {
  try {
    const parsedUrl = new URL(url);
    let applicationId = parsedUrl.searchParams.get("application_id");

    // If applicationId is not found in the search params, try to extract it from the pathname
    if (!applicationId) {
      const pathParts = parsedUrl.pathname.split("/");
      const applicationsIndex = pathParts.indexOf("applications");
      if (
        applicationsIndex !== -1 &&
        applicationsIndex + 1 < pathParts.length
      ) {
        applicationId = pathParts[applicationsIndex + 1];
      }
    }

    return applicationId ?? null;
  } catch (e) {
    return null;
  }
}

export function getGuideInternalPageUrlFromPublicUrl(publicUrl: string) {
  try {
    const url = new URL(publicUrl);

    url.pathname = `/internal${url.pathname}`;

    return url.toString();
  } catch (e) {
    return undefined;
  }
}
