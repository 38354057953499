import { useToast } from "@resource/atlas/toast/use-toast";
import copy from "copy-to-clipboard";
import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

export const CREATE_INTERNAL_MAGIC_LINK_MUTATION = gql(`
  mutation CreateInternalMagicLinkForGuideMutation($input: CreateInternalMagicLinkForGuideInput!) {
    createInternalMagicLinkForGuide(input: $input) {
      magicLink
      success
      code
      message
    }
  }
`);

export default function useCopyGuideMagicLink({
  guideId,
}: {
  guideId?: string;
}) {
  const [createInternalMagicLinkForGuide] = useMutation(
    CREATE_INTERNAL_MAGIC_LINK_MUTATION
  );

  const { sendToast } = useToast();

  const copyGuideMagicLink = useCallback(async () => {
    if (!guideId) {
      return;
    }

    // Avoid creating lots of magic links unecessarily
    const localStorageKey = `internal-magic-link-${guideId}`;
    const magicLink = localStorage.getItem(localStorageKey);

    if (magicLink) {
      copy(magicLink);
      sendToast("Guide magic link copied", { variant: "success" });
    } else {
      const { data } = await createInternalMagicLinkForGuide({
        variables: {
          input: {
            guideId,
          },
        },
      });
      if (data?.createInternalMagicLinkForGuide?.magicLink) {
        localStorage.setItem(
          localStorageKey,
          data.createInternalMagicLinkForGuide.magicLink
        );
        copy(data.createInternalMagicLinkForGuide.magicLink);
      }
    }
  }, [guideId, createInternalMagicLinkForGuide, sendToast]);

  return { copyGuideMagicLink };
}
