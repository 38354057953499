import { useEffect, useState } from "react";

export default function useDebouncedSearch(defaultTerm: string) {
  const [searchTerm, setSearchTerm] = useState(defaultTerm);
  const [debouncedTerm, setDebouncedTerm] = useState(defaultTerm);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedTerm(searchTerm.trim()), 200);
    return () => clearTimeout(timeout);
  }, [searchTerm]);

  return { searchTerm, setSearchTerm, debouncedTerm };
}
