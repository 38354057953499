import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { atlasRingLock } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import type { AtlasMenuContentItem } from "@resource/atlas/menu/types";
import { useMenuState } from "@resource/atlas/menu/use-menu-state";
import { useAuthContext } from "auth/context";
import { useRoutes } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/routes";
import { getGuideInternalPageUrlFromPublicUrl } from "client/components/internal/__utils/urls";
import useCopyGuideMagicLink from "client/components/internal/hooks/useCopyGuideMagicLink";
import NextLink from "next/link";
import { useMemo } from "react";

import { canResetCandidateForDev } from "../__utils/utils";
import { ResetCandidateForDevConfirmationDialog } from "../guide/ResetCandidateForDevConfirmationDialog";

export function InternalDashboardsMenu({
  guidePublicUrl,
  guideId,
}: {
  guidePublicUrl?: string;
  guideId?: string;
}) {
  const routes = useRoutes();
  const { user } = useAuthContext();
  const menuState = useMenuState();
  const resetCandidateConfirmationDialogStore = useDialogStore();

  const { copyGuideMagicLink } = useCopyGuideMagicLink({ guideId });

  const internalGuidePageHref = guidePublicUrl
    ? getGuideInternalPageUrlFromPublicUrl(guidePublicUrl)
    : null;

  const items = useMemo((): AtlasMenuContentItem[] => {
    const showInternalGuidePageLink =
      (routes.organizationSlug !== "__unknown__" &&
        routes.shortId !== "__unknown__" &&
        routes.shortId &&
        routes.relativePath !== "/internal") ||
      !!internalGuidePageHref;

    const internalGuidePageLink: AtlasMenuContentItem = {
      _type: "item",
      children: "Internal Guide Page",
      key: "internal-guide-page",
      renderWrapper: (props) => (
        <NextLink
          href={
            internalGuidePageHref ??
            `/internal/guide/${routes.organizationSlug}/${routes.shortId}`
          }
          {...props}
        />
      ),
    };

    const copyGuideMagicLinkOption: AtlasMenuContentItem = {
      _type: "item",
      children: "Copy Guide magic link",
      key: "copy-guide-magic-link",
      onClick: copyGuideMagicLink,
    };

    const resetCandidateForDevOption: AtlasMenuContentItem = {
      _type: "item",
      children: "Reset candidate for dev",
      key: "reset-candidate-for-dev",
      isDestructive: true,
      onClick: () => {
        resetCandidateConfirmationDialogStore.show();
      },
    };

    return [
      ...(showInternalGuidePageLink ? [internalGuidePageLink] : []),
      ...(guideId ? [copyGuideMagicLinkOption] : []),
      ...(guideId && canResetCandidateForDev()
        ? [resetCandidateForDevOption]
        : []),
    ];
  }, [
    routes.organizationSlug,
    routes.shortId,
    routes.relativePath,
    internalGuidePageHref,
    guideId,
    copyGuideMagicLink,
    resetCandidateConfirmationDialogStore,
  ]);

  if (!user?.isSuperuser || !items?.length) {
    return null;
  }

  return (
    <>
      <Menu.Root placement="bottom-end" state={menuState}>
        <Menu.Trigger>
          <Button isGhost icon={atlasRingLock} />
        </Menu.Trigger>
        <Menu.Content items={items} />
      </Menu.Root>
      {guideId && canResetCandidateForDev() && (
        <ResetCandidateForDevConfirmationDialog
          store={resetCandidateConfirmationDialogStore}
          guideId={guideId}
        />
      )}
    </>
  );
}
