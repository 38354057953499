export const HELP_CENTER_PAGE =
  "https://support.guide.co/en/collections/3873054-legacy-guide-help-center";
export const V2_HELP_CENTER_PAGE =
  "https://intercom.help/guide-v2-help-center/en";
export const GUIDE_ACADEMY_PAGE = "https://academy.guide.co/";
export const PRIVACY_POLICY_PAGE = "https://guide.co/privacy/";
export const TERMS_OF_SERVICE_PAGE = "https://guide.co/terms/";
export const CHROME_EXTENSION_INSTALL_PAGE = `https://chrome.google.com/webstore/detail/guide/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_IDENTIFIER}`;
export const CHROME_EXTENSION_TUTORIAL =
  "https://support.guide.co/en/articles/5568756-the-new-guide-extension-for-greenhouse";
export const SEND_GUIDES_TUTORIAL = "TODO";
export const HIRING_PLANS_TUTORIAL =
  "https://support.guide.co/en/articles/6465031-understanding-hiring-plans";
export const MESSAGE_TEMPLATES_TUTORIAL =
  "https://support.guide.co/en/articles/7062228-using-message-templates";
export const GUIDE_TEMPLATES_TUTORIAL =
  "https://support.guide.co/en/articles/7127058-guide-templates";
export const CHANGE_GUIDE_STATUS_SUPPORT_PAGE =
  "https://intercom.help/guide-v2-help-center/en/articles/9298304-changing-a-candidate-s-guide-status";
export const MARK_AS_OFFER_SUPPORT_PAGE =
  "https://intercom.help/guide-v2-help-center/en/articles/9296008-offer-status-in-guide";
export const MARK_AS_REJECTED_SUPPORT_PAGE =
  "https://intercom.help/guide-v2-help-center/en/articles/9296010-rejected-status-in-guide";
export const MARK_AS_HIRED_SUPPORT_PAGE =
  "https://intercom.help/guide-v2-help-center/en/articles/9296011-hired-status-in-guide";
export const CONTINUITY_GMAIL_FILTER_SUPPORT_URL =
  "https://intercom.help/guide-v2-help-center/en/articles/9376688-manage-guide-messages-with-a-gmail-filter";
