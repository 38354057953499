import useModalContext, { ModalName } from "components/Generic/ModalContext";
import { HTMLAttributeAnchorTarget, useCallback } from "react";

import useExtension from "./useExtension";

const useExtensionLink = () => {
  const { setActive } = useModalContext();
  const { isInstalled } = useExtension();

  const generateHref = useCallback((href: string) => {
    const url = new URL(href);
    url.searchParams.append("guide_open", "1");
    return url.href;
  }, []);

  const navigate = useCallback(
    async (href: string, target?: HTMLAttributeAnchorTarget) => {
      const generatedHref = generateHref(href);
      const installed = await isInstalled();
      const openHref = () => {
        window.focus();
        window.open(generatedHref, target ?? "_blank");
      };
      if (!installed) {
        setActive(ModalName.EXTENSION_INSTALL, { onInstalled: openHref });
      } else {
        openHref();
      }
    },
    [generateHref, setActive, isInstalled]
  );

  return { generateHref, navigate };
};

export default useExtensionLink;
