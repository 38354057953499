import { Button } from "@resource/atlas/button/Button";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { trpc } from "client/trpc";
import { useCallback } from "react";

export function ResetCandidateForDevConfirmationDialog({
  store,
  guideId,
}: {
  store: DialogStore;
  guideId: string;
}) {
  const resetCandidateForDev =
    trpc.internal.guide.resetCandidateForDev.useMutation({
      onSuccess: () => {
        store.hide();
        window.location.reload();
      },
    });

  const handleReset = useCallback(async () => {
    await resetCandidateForDev.mutateAsync({
      guideId,
    });
  }, [resetCandidateForDev, guideId]);

  return (
    <Dialog store={store}>
      <View
        className="text-body-md"
        header={{
          title: "Reset Candidate for Development",
        }}
        footer={{
          leftActions: (
            <Button isGhost onClick={() => store.hide()}>
              Cancel
            </Button>
          ),
          rightActions: (
            <Button
              variant="danger"
              onClick={handleReset}
              isLoading={resetCandidateForDev.isPending}
            >
              Reset Candidate
            </Button>
          ),
        }}
      >
        Are you sure you want to reset this candidate for development? This
        action will:
        <ul className="list-disc list-inside mt-2">
          <li>Archive all scheduling requests</li>
          <li>Cancel and archive all interviews</li>
        </ul>
        <p className="mt-2 text-red-500 text-body-md-heavy">
          This action is irreversible and should only be used in development
          environments.
        </p>
      </View>
    </Dialog>
  );
}
