import { ApolloError } from "@apollo/client";
import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import { serviceGuide } from "@resource/atlas/icons";
import { useExtensionController } from "client/app/extension/__components/ExtensionControllerProvider";
import { useRouter } from "next/router";
import inIframe from "utils/in-iframe";

type ErrorCode = "500" | "404";

export type ErrorProps = {
  onRecover?: () => void;
  errorCode?: ErrorCode;
  error?: Error;
};

type Content = {
  header: JSX.Element;
  title: string;
  subtitle: string;
  buttonLabel: string;
  defaultOnRecover: () => void;
};

export default function ErrorPage({ onRecover, errorCode, error }: ErrorProps) {
  const router = useRouter();
  const { sendMessageToParent } = useExtensionController();

  const mapErrorCodeToContent: {
    [code in ErrorCode]: Content;
  } = {
    "404": {
      header: (
        // TODO: get correct icon
        <Icon content={serviceGuide} className="w-12 h-12" />
      ),
      title: "Page not found",
      subtitle: "Sorry, we couldn’t find the page you’re looking for.",
      buttonLabel: "Go back home",
      defaultOnRecover: () => {
        if (inIframe()) {
          sendMessageToParent({
            command: "reload",
          });
        } else {
          router.push("/");
        }
      },
    },
    "500": {
      header: <p className="text-[3rem] leading-[1]">🫠</p>,
      title: "Uh oh!",
      subtitle:
        "Looks like you uncovered a bug 🪲. Please reach out to let support know and get this resolved.",
      buttonLabel: "Try again",
      defaultOnRecover: () => router.reload(),
    },
  };
  // need to standardize our server side handling if we want this to actually work
  const is404 =
    error &&
    error instanceof ApolloError &&
    error.graphQLErrors?.[0]?.extensions?.code === "NOT_FOUND";
  const content = mapErrorCodeToContent[errorCode || (is404 ? "404" : "500")];

  return (
    <div className="flex flex-col w-full h-full min-h-[50vh] justify-center items-center space-y-4">
      {content.header}
      <div className="max-w-lg p-4 text-center space-y-2">
        <p className="text-h2">{content.title}</p>
        <p className="text-body-md text-subtle">{content.subtitle}</p>
      </div>
      <Button onClick={onRecover ?? content.defaultOnRecover}>
        {content.buttonLabel}
      </Button>
    </div>
  );
}
