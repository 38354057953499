import { useRouter } from "next/router";

// utils
// -----

function assertIsNotArray<T>(
  value: T
): asserts value is Exclude<T, Array<unknown>> {
  if (Array.isArray(value)) throw new Error("Value is array");
}

// config
// ------

const SERVER_FALLBACK_VALUE = "__unknown__";

export const BASE_PATH_PREFIX = "/guide/";
export const BASE_PATHNAME = "/guide/[organizationSlug]/[shortId]";

export const HOMEPAGE_PATH = "";

export const UPDATES_PATH = "/messages";
export const UPDATES_PATHNAME = `${BASE_PATHNAME}${UPDATES_PATH}`;

export const UPDATE_PATH_PREFIX = "/message/";
export const UPDATE_PATHNAME = `${BASE_PATHNAME}${UPDATE_PATH_PREFIX}[messageId]`;

export const INTERVIEWS_PATH = "/interviews";
export const INTERVIEWS_PATHNAME = `${BASE_PATHNAME}${INTERVIEWS_PATH}`;

// route utils
// -----------

function getBaseRoute(organizationSlug?: string, shortId?: string) {
  if (!organizationSlug || !shortId) return SERVER_FALLBACK_VALUE;
  return `${BASE_PATH_PREFIX}${organizationSlug}/${shortId}`;
}

function getRelativeRoute(
  path: string,
  organizationSlug?: string,
  shortId?: string
) {
  if (!organizationSlug || !shortId) return SERVER_FALLBACK_VALUE;
  const basePath = getBaseRoute(organizationSlug, shortId);
  return path.replace(basePath, "");
}

export function getRelativeUpdateRoute(updateId: string) {
  return updateId ? `${UPDATE_PATH_PREFIX}${updateId}` : UPDATES_PATH;
}

export function useRoutes() {
  const { query, asPath, pathname, isReady } = useRouter();
  const { organizationSlug, shortId, requireLoggedIn } = query;
  assertIsNotArray(organizationSlug);
  assertIsNotArray(shortId);

  const basePath = getBaseRoute(organizationSlug, shortId);
  const relativePath = getRelativeRoute(asPath, organizationSlug, shortId);

  return {
    pathname,
    organizationSlug: organizationSlug ?? SERVER_FALLBACK_VALUE,
    shortId: shortId ?? SERVER_FALLBACK_VALUE,
    requireLoggedIn: requireLoggedIn === "true",
    basePath,
    relativePath,
    isReady,
  };
}

// use link
// --------

export type UseLinkOptions = {
  to: string;
  toPathname?: string;
  activeFn?: (data: { path: string; pathname: string }) => boolean;
  enabledFn?: (data: { path: string; pathname: string }) => boolean;
};

export function useLink({
  to,
  toPathname: relativeToPathname,
  activeFn,
  enabledFn,
}: UseLinkOptions) {
  const { basePath, relativePath, pathname } = useRoutes();

  const href = `${basePath}${to}`;
  const toPathname = `${BASE_PATHNAME}${relativeToPathname ?? to}`;
  const cbData = { path: relativePath, pathname };
  const isActive =
    activeFn?.(cbData) ?? (relativePath === to || pathname === toPathname);
  const isEnabled = enabledFn?.(cbData) ?? !isActive;

  return { href, isActive, isEnabled };
}
