import { useFocusRing } from "@react-aria/focus";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import type { PressEvents } from "@react-types/shared";
import clsx from "clsx";
import { ForwardedRef, ReactElement } from "react";

import { atlasChevronDown, atlasClose } from "../../icons/atlas";
import {
  excludePressProps,
  overloadedForwardRef,
  setTooltipConfig,
  useDisabledLinkProps,
  usePress,
} from "../__utils/__deprecated";
import { createComponentUtils } from "../__utils/atlas";
import { Avatar } from "../avatar/Avatar";
import { AtlasAvatarProps } from "../avatar/types";
import { Icon } from "../icon/Icon";
import type {
  AtlasBadgeButtonProps,
  AtlasBadgeDivProps,
  AtlasBadgeLinkProps,
  AtlasBadgeOwnProps,
  AtlasBadgeProps,
  AtlasBadgeWithRef,
} from "./types";

// config
// ------

const COMPONENT_NAME = "Badge";
const DEFAULT_PROPS = {
  size: "medium",
  styles: "subtle",
  variant: "default",
} as const;

const DROPDOWN_ICON = atlasChevronDown;
const DISMISS_ICON = atlasClose;

const AVATAR_SIZE_MAP: Record<
  NonNullable<AtlasBadgeProps["size"]>,
  NonNullable<AtlasAvatarProps["size"]>
> = {
  large: "small",
  medium: "xs",
  small: "xs",
};

const { ROOT, el } = createComponentUtils(COMPONENT_NAME);

// dismiss button component
// ------------------------

type DismissButtonProps = PressEvents & {
  isDisabled?: boolean;
  isFocusDisabled?: boolean;
};

function DismissButton({
  isDisabled,
  isFocusDisabled,
  ...props
}: DismissButtonProps) {
  // behavior
  // --------

  const { pressProps, isPressed } = usePress({ ...props, isDisabled });
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing();
  const optionalFocusProps = isFocusDisabled ? { tabIndex: -1 } : focusProps;
  const disabledProps = { disabled: isDisabled };

  // style
  // -----

  const styleProps = {
    className: clsx(el`dismiss-button`, {
      "is-focus-visible": isFocusVisible,
      "is-hovered": isHovered,
      "is-pressed": isPressed,
      "is-disabled": isDisabled,
    }),
  };

  // composition
  // -----------

  const buttonProps = mergeProps(
    pressProps,
    hoverProps,
    optionalFocusProps,
    disabledProps,
    styleProps
  );

  // rendering
  // ---------

  return (
    <button type="button" {...buttonProps}>
      <Icon
        className={el`dismiss-button-icon`}
        content={DISMISS_ICON}
        size="custom"
      />
    </button>
  );
}

// badge component
// ---------------

function getRootComponent({
  isLink,
  isButton,
}: {
  isLink?: boolean;
  isButton?: boolean;
}) {
  if (isLink && isButton)
    throw new Error(
      "[Badge] 'isLink' and 'isButton' can't be 'true' at the same time."
    );
  if (isLink) return "a";
  if (isButton) return "button";
  return "div";
}

function BadgeComponent(
  props: AtlasBadgeOwnProps & AtlasBadgeDivProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement;
function BadgeComponent(
  props: AtlasBadgeOwnProps & AtlasBadgeButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
): ReactElement;
function BadgeComponent(
  props: AtlasBadgeOwnProps & AtlasBadgeLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
): ReactElement;
function BadgeComponent(
  {
    size = DEFAULT_PROPS.size,
    styles = DEFAULT_PROPS.styles,
    variant = DEFAULT_PROPS.variant,
    isDropdown,
    isDismissible,
    isSelected,
    icon,
    children,
    onDismiss,
    image,
    initials,
    UNSAFE_isFocusDisabled: isFocusDisabled,
    ...tmpProps
  }: AtlasBadgeProps,
  ref:
    | ForwardedRef<HTMLDivElement>
    | ForwardedRef<HTMLButtonElement>
    | ForwardedRef<HTMLAnchorElement>
): ReactElement {
  // necessary for union discrimination to work
  // TODO: remove once we upgrade TypeScript - see: https://github.com/microsoft/TypeScript/pull/46266
  const { isLink, isButton, ...props } = tmpProps;
  const isInteractive = isButton || isLink;
  // TODO: should probably clean this up if/when we split avatar badges into a different component
  const isDisabled = props.disabled;
  // const isDisabled = isInteractive && props.disabled;

  // behavior
  // --------

  const isInteractionDisabled = isDisabled || !isInteractive;
  const { pressProps, isPressed } = usePress({
    ...props,
    isDisabled: isInteractionDisabled,
  });
  const { hoverProps, isHovered } = useHover({
    isDisabled: isInteractionDisabled,
  });
  const { focusProps, isFocusVisible } = useFocusRing();
  const optionalFocusProps = isFocusDisabled ? { tabIndex: -1 } : focusProps;
  const buttonTypeProps = isButton ? { type: "button" } : {};
  const disabledLinkProps = useDisabledLinkProps({ isLink, isDisabled });
  // TODO: should probably clean this up if/when we split avatar badges into a different component
  const disabledDivProps = !isInteractive ? { disabled: undefined } : {};

  // style
  // -----

  const hasTrailingIcon = isDropdown || isDismissible;

  const styleProps = {
    className: clsx(
      `${ROOT} styles-${styles} size-${size} variant-${variant}`,
      {
        "is-icon": !children && !hasTrailingIcon,
        "has-icon": icon,
        "has-trailing-icon": hasTrailingIcon,
        "has-dismiss": isDismissible,
        "is-interactive": isInteractive,
        "is-hovered": isHovered,
        "is-pressed": isPressed,
        "is-selected": isSelected,
        "is-disabled": isDisabled,
        "is-focus-visible": isFocusVisible,
      }
    ),
  };

  // composition
  // -----------

  const mergedProps = mergeProps(
    pressProps,
    hoverProps,
    optionalFocusProps,
    styleProps,
    buttonTypeProps,
    excludePressProps(props)
  );

  // using object spread (vs. mergeProps) allows "removing"
  // previously defined props by setting them to 'undefined'
  const badgeProps = {
    ...mergedProps,
    ...disabledLinkProps,
    ...disabledDivProps,
  };

  // rendering
  // ---------

  const RootComponent = getRootComponent({ isLink, isButton });

  return (
    <RootComponent ref={ref} {...badgeProps}>
      {styles === "avatar" && (
        <Avatar
          className={el`avatar`}
          variant="user"
          size={AVATAR_SIZE_MAP[size]}
          image={image}
          initials={initials}
          UNSAFE_disableFocus
        />
      )}
      {icon && <Icon className={el`icon`} content={icon} size="custom" />}
      {children && (
        <span
          className="truncate"
          title={typeof children === "string" ? children : ""}
        >
          {children}
        </span>
      )}
      {isDropdown && !isDismissible && (
        <Icon
          content={DROPDOWN_ICON}
          size="custom"
          className={el`dropdown-icon`}
        />
      )}
      {isDismissible && !isDropdown && (
        <DismissButton
          onPress={onDismiss}
          isDisabled={isDisabled}
          isFocusDisabled={isFocusDisabled}
        />
      )}
    </RootComponent>
  );
}

/**
 * A visual way to draw attention to a component, showing an icon, label or both.
 * Badges can have dropdown or be dismissible. Badges can act as buttons.
 */
const Badge = overloadedForwardRef<AtlasBadgeWithRef>(BadgeComponent);
setTooltipConfig(Badge, { mode: "slot" });

export default Badge;
