import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleCross } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import type { AtlasMenuContentItem } from "@resource/atlas/menu/types";
import { useMenuState } from "@resource/atlas/menu/use-menu-state";
import TextField from "@resource/atlas/textfield/TextField";
import { useAuthContext } from "auth/context";
import { gql } from "generated/graphql-codegen";
import { AvailableOrganizationsQuery } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import { useEventCallback } from "react-hooks/useEventCallback";
import useQuery from "utils/useQuery";

type Org = NonNullable<
  AvailableOrganizationsQuery["currentUser"]
>["availableOrganizations"][number];

const GET_AVAILABLE_ORGANIZATIONS = gql(`
query AvailableOrganizations($organizationSearch: String, $userSearch: String) {
    currentUser {
      id
      availableOrganizations(search: $organizationSearch) {
        id
        name
        defaultAvatarImageUrl
      }
    }
    userMemberships(search: $userSearch) {
      id
      name
      email
      imageUrl
      userId
    }
  }
`);

function useEnsureOrg(orgs?: Org[]) {
  const { organizationIdCookie, organizationIdCookieLoading, switchToOrg } =
    useAuthContext();
  const done = useRef(false);

  useEffect(() => {
    if (!orgs || done.current || organizationIdCookieLoading) return;
    done.current = true;

    if (organizationIdCookie) return;
    const firstOrg = orgs[0];
    if (firstOrg) switchToOrg(firstOrg.id);
  }, [organizationIdCookie, organizationIdCookieLoading, orgs, switchToOrg]);
}

function OrganizationSwitcher() {
  const {
    debouncedTerm: orgDebouncedTerm,
    searchTerm: orgSearchTerm,
    setSearchTerm: setOrgSearchTerm,
  } = useDebouncedSearch("");
  const {
    debouncedTerm: userDebouncedTerm,
    searchTerm: userSearchTerm,
    setSearchTerm: setUserSearchTerm,
  } = useDebouncedSearch("");
  const {
    user,
    organizationIdCookie,
    organizationIdCookieLoading,
    impersonationMembershipIdCookie,
    switchToOrg,
    impersonateUser,
  } = useAuthContext();
  const orgMenuState = useMenuState();
  const userMenuState = useMenuState();
  const { data, loading } = useQuery(GET_AVAILABLE_ORGANIZATIONS, {
    skip: !user || (!orgMenuState.open && !userMenuState.open),
    throwOnError: false,
    variables: {
      organizationSearch: orgDebouncedTerm,
      userSearch: userDebouncedTerm,
    },
  });
  const orgs = data?.currentUser?.availableOrganizations;
  const userMemberships = data?.userMemberships;

  // ensure that there's a current org set
  useEnsureOrg(orgs);

  const switchToOrgCb = useEventCallback(switchToOrg);
  const impersonateUserCb = useEventCallback(impersonateUser);

  const items = useMemo(
    () =>
      orgs?.map(
        ({ id, name, defaultAvatarImageUrl }): AtlasMenuContentItem => ({
          _type: "item",
          children: name,
          renderContent: (props) => (
            <div className="flex items-center gap-2 truncate">
              <Avatar
                image={defaultAvatarImageUrl}
                name={`${props.children}`}
                size="small"
              />
              {props.children}
            </div>
          ),
          value: id,
          key: id,
          isSelectable: true,
          isSelected: organizationIdCookie === id,
          onSelect: () => switchToOrgCb(id),
        })
      ) ?? [],
    [orgs, switchToOrgCb, organizationIdCookie]
  );

  const userItems: AtlasMenuContentItem[] = useMemo(() => {
    // This is necessary due to this bug in NextJS 13.4.8+ https://github.com/vercel/next.js/issues/52188
    const options: AtlasMenuContentItem[] = _(userMemberships)
      .map((userMembership) => {
        return userMembership.id !== user?.currentUserMembership?.id
          ? ({
              _type: "item" as const,
              children: userMembership.name,
              renderContent: (props) => (
                <div className="flex items-center gap-2 truncate">
                  <Avatar
                    image={userMembership.imageUrl}
                    name={`${props.children}`}
                    size="small"
                  />{" "}
                  <div className="flex flex-col">
                    <span className="truncate">{props.children}</span>
                    {userMembership.email !== props.children && (
                      <span className="text-subtle truncate">
                        {userMembership.email}
                      </span>
                    )}
                  </div>
                </div>
              ),
              value: userMembership.userId,
              key: userMembership.userId,
              isSelectable: true,
              isSelected: impersonationMembershipIdCookie === userMembership.id,
              onSelect: () => impersonateUserCb(userMembership.id),
            } as AtlasMenuContentItem)
          : null;
      })
      .compact()
      .value();
    return [
      ...(impersonationMembershipIdCookie
        ? [
            {
              _type: "item" as const,
              children: "Clear impersonation user",
              renderContent: (props) => (
                <div className="flex items-center gap-1.5">
                  <Icon
                    content={atlasCircleCross}
                    className="text-subtle w-4 h-4"
                  />{" "}
                  {props.children}
                </div>
              ),
              value: "impersonation-clear",
              key: "impersonation-clear",
              onSelect: () => impersonateUserCb(""),
            } as AtlasMenuContentItem,
          ]
        : []),
      ...options,
    ];
  }, [
    userMemberships,
    impersonateUserCb,
    impersonationMembershipIdCookie,
    user,
  ]);

  const currentOrg = useMemo(() => user?.currentOrganization, [user]);
  const currentImpersonatedUser = useMemo(() => {
    return impersonationMembershipIdCookie ? user?.currentUserMembership : null;
  }, [impersonationMembershipIdCookie, user?.currentUserMembership]);

  const orgCount = user?.availableOrganizationsCount ?? 0;

  if (orgCount < 2 || organizationIdCookieLoading) {
    return null;
  }

  return (
    <div className="flex">
      <Menu.Root placement="bottom-end" state={orgMenuState}>
        <Menu.Trigger>
          <Avatar
            key={currentOrg?.id}
            image={currentOrg?.defaultAvatarImageUrl}
            name={currentOrg?.name}
          />
        </Menu.Trigger>
        <Menu.Content
          isLoading={loading}
          items={items}
          className="w-72"
          header={
            <TextField
              value={orgSearchTerm}
              onChange={setOrgSearchTerm}
              placeholder="Search"
              aria-label="Search"
              autoFocus
            />
          }
        />
      </Menu.Root>
      {user?.isSuperuser && (
        <Menu.Root placement="bottom-end" state={userMenuState}>
          <Menu.Trigger>
            <Avatar
              key={currentImpersonatedUser?.id}
              image={currentImpersonatedUser?.imageUrl}
              name={currentImpersonatedUser?.name}
              className="-ml-2"
            />
          </Menu.Trigger>
          <Menu.Content
            isLoading={loading}
            items={userItems}
            className="w-72"
            header={
              <TextField
                value={userSearchTerm}
                onChange={setUserSearchTerm}
                placeholder="Search"
                aria-label="Search"
                autoFocus
              />
            }
          />
        </Menu.Root>
      )}
    </div>
  );
}

export default OrganizationSwitcher;
